import * as React from "react"

import Container from "../components/container"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Wufoo from "../components/wufoo"

const ContactUs = () => (
  <Layout page="internal">
    <Seo title="Contact Us" />
    <Container className="container">
      <div className="customWrapper">
        <div className="defaultWrapper">
          <h1>Contact Us</h1>
          <Wufoo formHash="zlsrox90i0hhw7" formHeight="747" />
        </div>
      </div>
    </Container>
  </Layout>
)

export default ContactUs
